var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "pagamento-form" } },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "vs-row",
                  { attrs: { "vs-type": "flex" } },
                  [
                    _c("ValidationProvider", {
                      staticClass: "vx-col w-full",
                      attrs: {
                        name: "nome do modelo de parâmetro",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Nome do modelo de parâmetro",
                                    size: "large",
                                    "danger-text": errors[0],
                                    danger: !!errors[0],
                                  },
                                  model: {
                                    value: _vm.pagamento.Name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pagamento, "Name", $$v)
                                    },
                                    expression: "pagamento.Name",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      staticClass: "vx-col lg:w-1/4 md:w-1/2 w-full",
                      attrs: {
                        name: "número maximo de parcelas",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c(
                                  "vs-select",
                                  {
                                    staticClass: "large w-full",
                                    attrs: {
                                      label: "Número máximo de parcelas",
                                      danger: !!errors[0],
                                      "danger-text": errors[0],
                                    },
                                    model: {
                                      value: _vm.pagamento.MaxNumberInstallment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pagamento,
                                          "MaxNumberInstallment",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pagamento.MaxNumberInstallment",
                                    },
                                  },
                                  _vm._l(7, function (item) {
                                    return _c("vs-select-item", {
                                      key: item,
                                      attrs: { value: item, text: item },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      staticClass:
                        "vx-col lg:w-1/4 md:w-1/2 w-full container-element",
                      attrs: {
                        name: "valor mínimo de parcela",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c(
                                  "label",
                                  { staticClass: "label-content label" },
                                  [_vm._v("Valor mínimo de parcela")]
                                ),
                                _c("currency-input", {
                                  staticClass:
                                    "w-full vs-inputx vs-input--input large hasValue",
                                  model: {
                                    value: _vm.pagamento.MinValueInstallment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagamento,
                                        "MinValueInstallment",
                                        $$v
                                      )
                                    },
                                    expression: "pagamento.MinValueInstallment",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0]",
                                      },
                                    ],
                                    staticClass: "text-danger text-xs",
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(errors[0]) +
                                        "\n        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      staticClass: "vx-col lg:w-1/4 md:w-1/2 w-full",
                      attrs: {
                        name: "vencimento mín da 1ª parcela (dias)",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "#####",
                                      expression: "'#####'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    label:
                                      "Vencimento mín da 1ª parcela (dias)",
                                    size: "large",
                                    "danger-text": errors[0],
                                    danger: !!errors[0],
                                  },
                                  model: {
                                    value:
                                      _vm.pagamento.MinDaysFirstInstallment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagamento,
                                        "MinDaysFirstInstallment",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "pagamento.MinDaysFirstInstallment",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      staticClass: "vx-col lg:w-1/4 md:w-1/2 w-full",
                      attrs: {
                        name: "vencimento máx da 1ª parcela (dias)",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "#####",
                                      expression: "'#####'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    label:
                                      "Vencimento máx da 1ª parcela (dias)",
                                    size: "large",
                                    "danger-text": errors[0],
                                    danger: !!errors[0],
                                  },
                                  model: {
                                    value:
                                      _vm.pagamento.MaxDaysFirstInstallment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagamento,
                                        "MaxDaysFirstInstallment",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "pagamento.MaxDaysFirstInstallment",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c(
                      "div",
                      { staticClass: "vx-col lg:w-1/3 md:w-1/2 w-full" },
                      [
                        _c(
                          "vs-dropdown",
                          {
                            staticClass: "w-full",
                            attrs: {
                              "vs-custom-content": "",
                              "vs-trigger-click": "",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "periodicidade de pagamento entre as parcelas",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label:
                                              "Periodicidade de pagamento entre as parcelas",
                                            size: "large",
                                            "danger-text": errors[0],
                                            danger: !!errors[0],
                                          },
                                          model: {
                                            value:
                                              _vm.pagamento.PaymentFrequency,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pagamento,
                                                "PaymentFrequency",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pagamento.PaymentFrequency",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c(
                              "vs-dropdown-menu",
                              { staticClass: "loginx" },
                              [
                                _vm._l(_vm.paymentFrequency, function (item) {
                                  return _c(
                                    "vs-dropdown-item",
                                    {
                                      key: item.value,
                                      class: {
                                        active:
                                          item.value ==
                                          _vm.pagamento.PaymentFrequency,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.pagamento.PaymentFrequency =
                                            item.value
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.text) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "#####",
                                      expression: "'#####'",
                                    },
                                  ],
                                  staticClass: "w-full pt-5",
                                  attrs: {
                                    label: "Outro:",
                                    placeholder: "Informe o intervalo desejado",
                                    size: "large",
                                  },
                                  model: {
                                    value: _vm.pagamento.PaymentFrequency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pagamento,
                                        "PaymentFrequency",
                                        $$v
                                      )
                                    },
                                    expression: "pagamento.PaymentFrequency",
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("ValidationProvider", {
                      staticClass: "vx-col lg:w-1/3 md:w-1/2 w-full",
                      attrs: {
                        name: "venc da última parcela antes do final de vigência (dias)",
                        rules: "required",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c(
                                  "vs-select",
                                  {
                                    staticClass: "large w-full",
                                    attrs: {
                                      label:
                                        "Venc da última parcela antes do final de vigência (dias)",
                                      "danger-text": errors[0],
                                      danger: !!errors[0],
                                    },
                                    model: {
                                      value:
                                        _vm.pagamento.DueDateLastInstallment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pagamento,
                                          "DueDateLastInstallment",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pagamento.DueDateLastInstallment",
                                    },
                                  },
                                  _vm._l(30, function (item) {
                                    return _c("vs-select-item", {
                                      key: item,
                                      attrs: { value: item, text: item },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c(
                      "div",
                      { staticClass: "vx-col lg:w-1/3 md:w-1/2 w-full" },
                      [
                        _c(
                          "div",
                          { staticClass: "switch-container" },
                          [
                            _c("label", [_vm._v("Cobrar juros nas parcelas?")]),
                            _c("vs-switch", {
                              staticClass: "ml-auto",
                              model: {
                                value: _vm.pagamento.HasInterestInstallment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pagamento,
                                    "HasInterestInstallment",
                                    $$v
                                  )
                                },
                                expression: "pagamento.HasInterestInstallment",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.pagamento.HasInterestInstallment
                      ? [
                          _c("ValidationProvider", {
                            staticClass: "vx-col lg:w-1/3 md:w-1/2 w-full",
                            attrs: {
                              name: "cobrar juros nas parcelas?",
                              rules: {
                                required: true,
                                max: _vm.pagamento.MaxNumberInstallment,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "vs-select",
                                        {
                                          staticClass: "large w-full",
                                          attrs: {
                                            label:
                                              "Número máximo de parcelas sem juros",
                                            "danger-text": errors[0],
                                            danger: !!errors[0],
                                          },
                                          model: {
                                            value:
                                              _vm.pagamento
                                                .MaxNumberInstallmentWithoutInterest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pagamento,
                                                "MaxNumberInstallmentWithoutInterest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pagamento.MaxNumberInstallmentWithoutInterest",
                                          },
                                        },
                                        _vm._l(7, function (item) {
                                          return _c("vs-select-item", {
                                            key: item,
                                            attrs: { value: item, text: item },
                                          })
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("ValidationProvider", {
                            staticClass: "vx-col lg:w-1/3 md:w-1/2 w-full",
                            attrs: { name: "(%) juros", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "#####",
                                            expression: "'#####'",
                                          },
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          label: "(%) juros",
                                          size: "large",
                                          "danger-text": errors[0],
                                          danger: !!errors[0],
                                        },
                                        model: {
                                          value:
                                            _vm.pagamento.InterestPercentage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagamento,
                                              "InterestPercentage",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "pagamento.InterestPercentage",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                !!_vm.tabPagamentoParameter &&
                _vm.tabPagamentoParameter.UniqueId
                  ? _c(
                      "vs-row",
                      {
                        staticClass: "mb-5",
                        attrs: { "vs-type": "flex", "vs-justify": "flex-end" },
                      },
                      [
                        _c(
                          "vs-col",
                          {
                            attrs: {
                              "vs-type": "flex",
                              "vs-align": "center",
                              "vs-sm": "12",
                              "vs-justify": "flex-end",
                            },
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "button-primary block pr-3",
                                on: {
                                  click: function ($event) {
                                    return _vm.openModalConfig()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          Gerenciar relacionamento do parâmetro com grupo/modalidade(s)\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vs-row",
                  { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
                  [
                    _c(
                      "vs-col",
                      {
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "end",
                          "vs-align": "center",
                          "vs-lg": "2",
                          "vs-sm": "12",
                        },
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "button-primary",
                            attrs: { disabled: invalid },
                            on: {
                              click: function ($event) {
                                return _vm.salvarPagamentoByClass()
                              },
                            },
                          },
                          [_vm._v("\n          Salvar\n        ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      !!_vm.tabPagamentoParameter && _vm.tabPagamentoParameter.UniqueId
        ? _c(
            "vs-popup",
            {
              staticClass: "relacinamento-modal",
              attrs: {
                title:
                  "Gerenciar relacionamento do parâmetro com grupo/modalidade(s)",
                active: _vm.showConfigModalityModal,
              },
              on: {
                "update:active": function ($event) {
                  _vm.showConfigModalityModal = $event
                },
              },
            },
            [
              _c("ValidationObserver", {
                staticClass: "mt-10",
                attrs: { tag: "div" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ invalid }) {
                        return [
                          _vm._l(
                            _vm.modalityGroupPagamento,
                            function (grupo, index) {
                              return _c(
                                "vs-row",
                                { key: index },
                                [
                                  _c("ValidationProvider", {
                                    staticClass: "vx-col md:w-1/4 w-full",
                                    attrs: {
                                      name: "grupo de modalidade",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "vs-select",
                                                {
                                                  staticClass: "large w-full",
                                                  attrs: {
                                                    label: "Grupo",
                                                    "danger-text": errors[0],
                                                    danger: !!errors[0],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.loadModalities(
                                                        grupo.ModalityGroupUniqueId,
                                                        index
                                                      )
                                                      grupo.Modalities = []
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      grupo.ModalityGroupUniqueId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        grupo,
                                                        "ModalityGroupUniqueId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "grupo.ModalityGroupUniqueId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.modalitiesGroups,
                                                  function (item, indexGroup) {
                                                    return _c(
                                                      "vs-select-item",
                                                      {
                                                        key: indexGroup,
                                                        attrs: {
                                                          value:
                                                            item.ModalityGroupUniqueId,
                                                          text: item.ModalityGroupName,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col md:w-2/4 w-full container-element",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label-content label" },
                                        [_vm._v("Modalidades")]
                                      ),
                                      _c("multiselect", {
                                        attrs: {
                                          disabled:
                                            !grupo.ModalityGroupUniqueId ||
                                            !_vm.getModalities(
                                              grupo.ModalityGroupUniqueId,
                                              index
                                            ),
                                          searchable: false,
                                          "internal-search": false,
                                          "clear-on-select": true,
                                          "max-height": 200,
                                          options: _vm.getModalities(
                                            grupo.ModalityGroupUniqueId,
                                            index
                                          ),
                                          "open-direction": "bottom",
                                          placeholder: "Selecione",
                                          taggable: "",
                                          multiple: "",
                                          groupSelect: "",
                                          trackBy: "ModalityName",
                                          label: "ModalityName",
                                          showLabels: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.validModalidadePacelamentoDiferenciado(
                                              index
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.validModalidadePacelamentoDiferenciado(
                                              index
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function ({ values }) {
                                                return values && values.length
                                                  ? [
                                                      _c(
                                                        "vs-chip",
                                                        {
                                                          attrs: {
                                                            closable: "",
                                                            transparent: "",
                                                            border: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeModality(
                                                                index,
                                                                0
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                " +
                                                              _vm._s(
                                                                values[0]
                                                                  .ModalityName
                                                              ) +
                                                              "\n              "
                                                          ),
                                                        ]
                                                      ),
                                                      values.length > 1
                                                        ? _c(
                                                            "vs-chip",
                                                            {
                                                              staticClass:
                                                                "float-right",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _c("vs-avatar", {
                                                                attrs: {
                                                                  icon: "add",
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n                " +
                                                                  _vm._s(
                                                                    values.length -
                                                                      1
                                                                  ) +
                                                                  "\n              "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : undefined
                                              },
                                            },
                                            {
                                              key: "option",
                                              fn: function (props) {
                                                return [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.option.ModalityName
                                                    ) + "\n            "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: grupo.Modalities,
                                          callback: function ($$v) {
                                            _vm.$set(grupo, "Modalities", $$v)
                                          },
                                          expression: "grupo.Modalities",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.hasError[index],
                                              expression: "hasError[index]",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [
                                          _vm._v(
                                            "\n            Selecione ao menos 1 modalidade\n          "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("ValidationProvider", {
                                    staticClass: "vx-col md:w-2/12 w-full",
                                    attrs: {
                                      name: "grupo de modalidade",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "vs-select",
                                                {
                                                  staticClass: "large w-full",
                                                  attrs: {
                                                    label:
                                                      "Número máximo de parcelas",
                                                    "danger-text": errors[0],
                                                    danger: !!errors[0],
                                                  },
                                                  model: {
                                                    value:
                                                      grupo.MaxNumberInstallment,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        grupo,
                                                        "MaxNumberInstallment",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "grupo.MaxNumberInstallment",
                                                  },
                                                },
                                                _vm._l(7, function (item) {
                                                  return _c("vs-select-item", {
                                                    key: item,
                                                    attrs: {
                                                      value: item,
                                                      text: item,
                                                    },
                                                  })
                                                }),
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "vx-col md:w-1/12 w-full" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "onpoint-trash text-primary",
                                        attrs: { role: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removePagamentoDiferenciado(
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "vs-row",
                            {
                              staticClass: "mb-5",
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "vs-col",
                                {
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "end",
                                    "vs-align": "center",
                                    "vs-col": "12",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { color: "primary", type: "flat" },
                                      on: {
                                        click: _vm.addParcelamentoDiferenciado,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "onpoint-plus pr-2",
                                      }),
                                      _c("b", {}, [
                                        _vm._v(
                                          "Adicionar parcelamento diferenciado"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vs-row",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "vs-col",
                                {
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "end",
                                    "vs-align": "center",
                                    "vs-lg": "3",
                                    "vs-sm": "12",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "button-default mr-4",
                                      on: {
                                        click: function ($event) {
                                          _vm.showConfigModalityModal = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Cancelar\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "button-primary",
                                      attrs: {
                                        disabled:
                                          invalid ||
                                          _vm.hasParcelamendoDiferenciadoInvalido,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.salvarParcelamentoDiferenciado()
                                        },
                                      },
                                    },
                                    [_vm._v("\n            Salvar\n          ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  966363598
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }